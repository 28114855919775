import { API_URL } from '@/services/api.constant';

import { objectToParamString } from './course.api';

export async function getReviews() {
	const inputObject = {
		order: ['priority ASC', 'id DESC'],
		where: {
			branchId: 1, // id of Code4Change site
		},
	};

	const paramString = '?filter=' + objectToParamString(inputObject);

	const res = await fetch(`${API_URL}/reviews` + paramString, {
		cache: 'no-store',
	});
	const data = await res.json();
	return data;
}
