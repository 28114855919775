import { API_URL } from '@/services/api.constant';
import { objectToParamString } from '@/services/Api/course.api';

export async function getProjectBySlug(slug: string) {
	const inputObject = {
		include: [
			{
				relation: 'mediaContents',
				scope: {
					limit: 1,
					order: ['id DESC'],
				},
			},
		],
	};

	const paramString = '?filter=' + objectToParamString(inputObject);

	const res = await fetch(
		`${API_URL}/projects/detail/${slug}` + paramString,
		{
			cache: 'no-store',
		}
	);
	return res.json();
}
export async function getSeoProjectBySlug(slug: string) {
	const inputObject = {
		include: [
			{
				relation: 'mediaContents',
				scope: {
					limit: 1,
					order: ['id DESC'],
					fields: ['projectId', 'url'],
				},
			},
		],
		fields: ['id', 'name', 'description'],
	};

	const paramString = '?filter=' + objectToParamString(inputObject);

	const res = await fetch(
		`${API_URL}/projects/detail/${slug}` + paramString,
		{
			cache: 'no-store',
		}
	);
	return res.json();
}

export async function getProjects() {
	const inputObject = {
		order: ['index ASC', 'id DESC'],
		include: [
			{
				relation: 'mediaContents',
				scope: {
					limit: 1,
					order: ['id DESC'],
				},
			},
		],
		where: {
			branchId: 1, // id of Code4Change site
		},
	};

	const paramString = '?filter=' + objectToParamString(inputObject);

	const res = await fetch(`${API_URL}/projects` + paramString, {
		cache: 'no-store',
	});
	return res.json();
}
