import { baseURL, defaultApiSauceConfig, isSuccess } from './api';
import {
	IBlog,
	IChoice,
	IComment,
	ICommentBlog,
	IContact,
	ICourse,
	IEnrollment,
	ILesson,
	IProduct,
	IProgram,
	IProject,
	IQuestion,
	IReview,
	ISubmission,
	IUser,
} from './api.type';
import { Filter } from './loopback.type';

import apisauce from 'apisauce';

const createServiceAdminApi = () => {
	const api = apisauce.create({
		...defaultApiSauceConfig(),
		baseURL: baseURL,
	});

	const login = async (data?: { email: string; password: string }) =>
		api.post('/users/login', data);

	const createUser = async (data: IUser) => api.post('/users', data);

	const getUsers = async (filter?: Filter) =>
		api.get<{ data: IUser[]; count: number }>('/users', {
			filter,
		});

	const updateUser = async (id: number, data: IUser) =>
		api.patch('/admin/users/' + id, data, {});
	const deleteUser = async (id: number) => api.delete('/admin/users/' + id);
	const getContacts = async (filter?: Filter) =>
		api.get<{ data: IContact[]; count: number }>('/contacts', {
			filter,
		});

	const editContact = async (id: number, data: IContact) =>
		api.patch('/contacts/' + id, data, {});
	const deleteContact = async (id: number) => api.delete('/contacts/' + id);

	const getUserById = async (userId: number, filter?: Filter) =>
		api.get('/users/' + userId, {
			filter,
		});

	const lockUserById = async (userId: number) =>
		api.post('/admin/lock-user/' + userId);

	const getAdminGeneral = async () => {
		const res = await api.get('/admin/general');

		if (isSuccess(res)) return res.data;

		return {};
	};

	const createMediaContent = async (
		mediaType:
			| 'BLOG'
			| 'PROJECT'
			| 'REVIEW_OWNER_AVATAR'
			| 'COURSE'
			| 'PRODUCT',
		id: number,
		data: any
	) =>
		api.post('/media-contents/' + mediaType + '/' + id, data, {
			headers: {
				...api.headers,
				'content-type': 'multipart/form-data',
			},
		});

	// BLOG
	const getBlogs = async (filter?: Filter) =>
		api.get<{ data: IBlog[]; count: number }>('/blogs', {
			filter,
		});

	const createBlog = async (data: IBlog) => api.post('/blogs', data);

	const editBlog = async (id: number, data: IBlog) =>
		api.patch('/blogs/' + id, data);

	const deleteBlog = async (id: number) => api.delete('/blogs/' + id);

	const getBlogById = async (id: number, filter?: Filter) => {
		const res = await api.get<IBlog>('/blogs/' + id, { filter });

		if (isSuccess(res)) return res.data;

		return {};
	};

	const getCommentBlogs = async (blogId: number, filter?: Filter) =>
		api.get<{ data: ICommentBlog[]; count: number }>(
			'/blogs/' + blogId + '/comments',
			{
				filter: {
					...filter,
					where: {
						blogId: blogId || 0,
					},
				},
			}
		);

	// END BLOG
	// START PROJECT
	const getProjects = async (filter?: Filter) =>
		api.get<{ data: IProject[]; count: number }>('/projects', {
			filter,
		});

	const createProject = async (data: IProject) => api.post('/projects', data);

	const editProject = async (id: number, data: IProject) =>
		api.patch('/projects/' + id, data);

	const setProjectPriority = async (id: number, data: { status: boolean }) =>
		api.patch('/projects/' + id + '/set-priority', data, {});

	const deleteProject = async (id: number) => api.delete('/projects/' + id);

	const getProjectById = async (id: number, filter?: Filter) => {
		const res = await api.get<IProject>('/projects/' + id, { filter });

		if (isSuccess(res)) return res.data;

		return {};
	};

	// END PROJECT

	// PRODUCT
	const getProducts = async (filter?: Filter) =>
		api.get<{ data: IProduct[]; count: number }>('/admin/products', {
			filter,
		});

	const createProduct = async (data: IProduct) => api.post('/products', data);

	const editProduct = async (id: number, data: IProduct) =>
		api.patch('/products/' + id, data, {});

	const deleteProduct = async (id: number) => api.delete('/products/' + id);

	// COURSE
	const getCourses = async (filter?: Filter) =>
		api.get<{ data: ICourse[]; count: number }>('/admin/courses', {
			filter,
		});

	const createCourse = async (data: ICourse) => api.post('/courses', data);

	const editCourse = async (id: number, data: ICourse) =>
		api.patch('/courses/' + id, data);
	const deleteCourse = async (id: number) => api.delete('/courses/' + id);

	// PROGRAM
	const getPrograms = async (courseId: number | any, filter?: Filter) =>
		api.get<{ data: IProgram[]; count: number }>(
			'/admin/courses/' + courseId + '/programs',
			{
				filter,
			}
		);

	const createProgram = async (courseId: number | any, data: IProgram) =>
		api.post('/courses/' + courseId + '/programs', data);

	const editProgram = async (programId: number, data: IProgram) =>
		api.patch('/programs/' + programId, data, {});
	const deleteProgram = async (id: number | any) =>
		api.delete('/programs/' + id);

	// LESSON
	const getLessons = async (filter?: Filter) =>
		api.get<{ data: ILesson[]; count: number }>('/lessons', {
			filter,
		});

	const createLesson = async (data: ILesson) => api.post('/lessons', data);

	const editLesson = async (lessonId: number, data: ILesson) =>
		api.patch('/lessons/' + lessonId, data, {});

	const deleteLesson = async (lessonId: number) =>
		api.delete('/lessons/' + lessonId);

	// Enrollment
	const getEnrollments = async (filter?: Filter) =>
		api.get<{ data: IEnrollment[]; count: number }>('/admin/enrollments', {
			filter,
		});

	const createEnrollment = async (data: IEnrollment) =>
		api.post('/enrollments', data);

	const editEnrollment = async (enrollmentId: number, data: IEnrollment) =>
		api.patch('/admin/enrollments/' + enrollmentId, data, {});

	const deleteEnrollment = async (enrollmentId: number) =>
		api.delete('/admin/enrollments/' + enrollmentId);

	// Question
	const getQuestions = async (filter?: Filter) =>
		api.get<{ data: IQuestion[]; count: number }>('/questions', {
			filter,
		});

	const createQuestion = async (data: IQuestion) =>
		api.post('/questions', data);

	const editQuestion = async (id: number, data: IQuestion) =>
		api.patch('/questions/' + id, data, {});

	const deleteQuestion = async (id: number) => api.delete('/questions/' + id);

	// Choice
	const getChoices = async (filter?: Filter) =>
		api.get<{ data: IChoice[]; count: number }>('/choices', {
			filter,
		});

	const createChoice = async (data: IChoice) => api.post('/choices', data);

	const editChoice = async (id: number, data: IChoice) =>
		api.patch('/choices/' + id, data, {});

	const deleteChoice = async (id: number) => api.delete('/choices/' + id);

	// Comment
	const getComments = async (filter?: Filter) =>
		api.get<{ data: IComment[]; count: number }>('/comments', {
			filter,
		});

	const getChildCommentById = async (commentId: number, filter?: Filter) =>
		api.get<{ data: IComment[]; count: number }>(
			'/comments/' + commentId + '/child-comments',
			{
				filter,
			}
		);

	const createChildComment = async (commentId: number, data: any) =>
		api.post('/comments/' + commentId + '/child-comments', data);

	const getCommentById = async (commentId: number, filter?: Filter) =>
		api.get<{ data: IComment[]; count: number }>('/comments/' + commentId, {
			filter,
		});

	const createComment = async (data: IComment) => api.post('/comments', data);

	const editComment = async (id: number, data: IComment) =>
		api.patch('/admin/comments/' + id, data, {});

	const deleteComment = async (id: number) =>
		api.delete('/admin/comments/' + id);

	const deleteChildComment = async (id: number) =>
		api.delete('/admin/child-comments/' + id);

	const getReviews = async (filter?: Filter) =>
		api.get<{ data: IReview[]; count: number }>('/reviews', {
			filter,
		});

	const createReview = async (data: IReview) => api.post('/reviews', data);

	const editReview = async (id: number, data: IReview) =>
		api.patch('/reviews/' + id, data, {});

	const deleteReview = async (id: number) => api.delete('/reviews/' + id);

	const getReviewById = async (id: number) => {
		const res = await api.get<IReview>('/reviews/' + id);

		if (isSuccess(res)) return res.data;

		return {};
	};

	// Submission
	const getSubmissions = async (filter?: Filter) =>
		api.get<{ data: ISubmission[]; count: number }>('/submissions', {
			filter,
		});

	const createSubmission = async (data: ISubmission) =>
		api.post('/submissions', data);

	const editSubmission = async (id: number, data: ISubmission) =>
		api.patch('/submissions/' + id, data, {});

	const deleteSubmission = async (id: number) =>
		api.delete('/submissions/' + id);

	const getMediaContents = async (filter: Filter) =>
		await api.get('/media-contents/', { filter });
	const deleteMediaContent = async (id: number) =>
		api.delete('/media-contents/' + id);

	const getGeneral = async () => {
		const res = await api.get('/admin/general');

		if (isSuccess(res)) return res.data;

		return {};
	};

	const setProjectAvatar = async (id: number, data: { projectId: number }) =>
		api.patch(`/media-content/${id}/set-project-avatar/`, data, {});

	const createBlogArticleImage = async (data: any) =>
		api.post('/media-contents/blog/article', data, {
			headers: {
				...api.headers,
				'content-type': 'multipart/form-data',
			},
		});

	return {
		api,
		login,
		// Contact
		getContacts,
		editContact,
		deleteContact,
		// User
		createUser,
		updateUser,
		getUsers,
		getUserById,
		deleteUser,
		lockUserById,
		// Blog
		getBlogs,
		getAdminGeneral,
		createBlog,
		editBlog,
		deleteBlog,
		getBlogById,
		createBlogArticleImage,

		// Media-Content
		createMediaContent,
		deleteMediaContent,

		// Project
		getProjects,
		createProject,
		editProject,
		setProjectPriority,
		deleteProject,
		getProjectById,

		// Product
		getProducts,
		createProduct,
		editProduct,
		deleteProduct,

		// Course
		getCourses,
		createCourse,
		editCourse,
		deleteCourse,

		// Program
		getPrograms,
		createProgram,
		editProgram,
		deleteProgram,

		// Question
		getQuestions,
		createQuestion,
		editQuestion,
		deleteQuestion,

		// Choice
		getChoices,
		createChoice,
		editChoice,
		deleteChoice,

		// Lesson
		getLessons,
		createLesson,
		editLesson,
		deleteLesson,

		// Enrollment
		getEnrollments,
		createEnrollment,
		editEnrollment,
		deleteEnrollment,

		// Comment
		getComments,
		getCommentById,
		createComment,
		editComment,
		deleteComment,
		getChildCommentById,
		createChildComment,
		deleteChildComment,

		// Comment blog
		getCommentBlogs,

		//
		getReviews,
		createReview,
		editReview,
		deleteReview,
		getReviewById,
		getMediaContents,
		setProjectAvatar,
		getGeneral,

		// Submission
		getSubmissions,
		createSubmission,
		editSubmission,
		deleteSubmission,
	};
};

export const ServiceAdminApi = createServiceAdminApi();
export const setAdminApiAuthorization = (token: string) => {
	ServiceAdminApi.api.setHeaders({
		authorization: 'Bearer ' + token,
	});
};

const handleApiResponse = async (apiRequest: any) => {
	const res = await apiRequest();

	if (isSuccess(res)) return res.data;

	return {};
};
