import { API_URL } from '@/services/api.constant';

import { isSuccess, ServiceApi } from '../api';

export const objectToParamString = (obj: any): any => {
	return encodeURIComponent(JSON.stringify(obj));
};

export async function getNameCourse(slug: string) {
	const res = await ServiceApi.getCourseBySlug(slug, {
		fields: ['name'],
	});
	if (isSuccess(res)) {
		return res.data;
	}

	return {};
}

export async function getIdCourse(slug: string) {
	const res = await ServiceApi.getCourseBySlug(slug, {
		fields: ['id'],
	});
	if (isSuccess(res)) {
		return res.data;
	}

	return {};
}

export const getDataPrograms = async (slug: string) => {
	const res = await ServiceApi.getProgramsBySlug(slug, {
		include: [
			{
				relation: 'lessons',
			},
		],
	});
	if (isSuccess(res)) {
		return res.data;
	}

	return [];
};

export async function getProgramByCourseSlug(slug: string) {
	const inputObject = {
		include: [
			{
				relation: 'lessons',
				scope: {
					order: ['index ASC', 'id DESC'],
				},
			},
			{
				relation: 'questions',
				scope: {
					limit: 1,
					fields: ['programId'],
				},
			},
		],
		order: ['index ASC', 'id DESC'],
	};

	const paramString = '?filter=' + objectToParamString(inputObject);
	const res = await fetch(
		`${API_URL}/courses/${slug}/programs` + paramString,
		{ cache: 'no-store' }
	);
	return res.json();
}

export async function getCourseBySlug(slug: string) {
	const inputObject = {
		include: [
			{
				relation: 'mediaContents',
				scope: {
					limit: 1,
					order: ['id DESC'],
				},
			},
		],
	};

	const paramString = '?filter=' + objectToParamString(inputObject);

	const res = await fetch(`${API_URL}/courses/detail/${slug}` + paramString, {
		cache: 'no-store',
	});
	return res.json();
}

export async function getCourses() {
	const inputObject = {
		order: ['index ASC', 'id DESC'],
		include: [
			{
				relation: 'mediaContents',
				scope: {
					limit: 1,
					order: ['id DESC'],
				},
			},
			{
				relation: 'user',
				scope: {
					fields: ['id', 'firstName', 'lastName'],
				},
			},
		],
		where: {
			branchId: 1, // id of Code4Change site
		},
	};

	const paramString = '?filter=' + objectToParamString(inputObject);

	const res = await fetch(`${API_URL}/courses` + paramString, {
		cache: 'no-store',
	});
	const data = await res.json();
	return data;
}
