import { defaultFilter } from './api.constant';
import {
	IBlog,
	IChoice,
	IComment,
	ICommentBlog,
	IContact,
	ICourse,
	IEnrollment,
	ILesson,
	INews,
	INewsletters,
	IProduct,
	IProgram,
	IProject,
	IQuestion,
	ISubmission,
	IUser,
} from './api.type';
import { Filter } from './loopback.type';

import apisauce, { ApiResponse } from 'apisauce';

export const isDev = process.env.NODE_ENV === 'development';

export const baseURL = 'https://api.code4change.dev';
// export const baseURL = isDev
// 	? 'http://localhost:5000'
// 	: 'https://api.code4change.dev';

export const defaultApiSauceConfig = (headers?: any) => {
	return {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers,
		},
		timeout: 10000,
	};
};

const createServiceApi = () => {
	const api = apisauce.create({
		...defaultApiSauceConfig(),
		baseURL: baseURL,
	});

	const getUserMe = async () => api.get<IUser>('/users/me');
	const login = async ({
		email,
		password,
	}: {
		email: string;
		password: string;
	}) => api.post<{ token: string }>('/users/login', { email, password });

	const initResetPassword = async ({ email }: { email: string }) =>
		api.post('/users/reset-password/init', { email });

	const finishResetPassword = async ({
		resetKey,
		password,
	}: {
		resetKey: string;
		password: string;
	}) => api.post('/users/reset-password/finish', { resetKey, password });

	const loginGoogle = async (payload: {
		id: string;
		token: string;
		firstName: string;
		lastName: string;
		email: string;
	}) => api.post<{ token: string }>('/users/login/google', payload);

	const register = async (data: any) =>
		api.post<{ token: string }>('/users/register', data);

	const getNews = async (filter?: Filter) =>
		api.get<{ data: INews[]; count: number }>(
			'/news',
			filter ? { filter } : defaultFilter
		);

	const getNewDetailBySlug = async (slug: string, filter?: Filter) =>
		api.get<INews>('/news/detail/' + slug, { filter });

	const getProjects = async (filter?: Filter) =>
		api.get<{ data: IProject[]; count: number }>(
			'/projects',
			filter ? { filter } : defaultFilter
		);

	const getProjectBySlug = async (slug: string, filter?: Filter) =>
		api.get<IProject>('/projects/detail/' + slug, { filter });

	// Product
	const getProducts = async (filter?: Filter) =>
		api.get<{ data: IProduct[]; count: number }>(
			'/products',
			filter ? { filter } : defaultFilter
		);

	const getProductBySlug = async (slug: string, filter?: Filter) =>
		api.get<IProduct>('/products/detail/' + slug, { filter });

	const getCourses = async (filter?: Filter) =>
		api.get<ICourse[]>('/courses', filter ? { filter } : defaultFilter);

	const getCourseById = async (id: number, filter?: Filter) =>
		api.get<ICourse>('/courses/' + id, { filter });

	const viewCourse = async (id: number) =>
		api.patch('/courses/' + id + '/view');

	const getCourseBySlug = async (slug: string, filter?: Filter) =>
		api.get<ICourse>('/courses/detail/' + slug, { filter });

	const getProgramsBySlug = async (slug: string, filter?: Filter) =>
		api.get<IProgram[]>('/courses/' + slug + '/programs', { filter });

	const getLessonsByProgramId = async (id: number, filter?: Filter) =>
		api.get<ILesson>('/programs/' + id + '/lessons', { filter });

	const getPrograms = async (filter?: Filter) =>
		api.get<{ data: IProgram[]; count: number }>(
			'/programs',
			filter ? { filter } : defaultFilter
		);

	const getLessons = async (filter?: Filter) =>
		api.get<{ data: ILesson[]; count: number }>(
			'/lessons',
			filter ? { filter } : defaultFilter
		);
	const createLessonComplete = async (data: any) =>
		api.post(`/lessons/status`, data);

	const getBlogs = async (filter?: Filter) =>
		api.get<{ data: IBlog[]; count: number }>(
			'/blogs',
			filter ? { filter } : defaultFilter
		);

	const getBlogBySlug = async (slug: string, filter?: Filter) =>
		api.get<IBlog>(`/blogs/detail/${slug}`, {
			filter,
		});

	const viewBlog = async (id: number) => api.patch('/blogs/' + id + '/view');

	const getCommentBlogs = async (blogId: number, filter?: Filter) =>
		api.get<{ data: ICommentBlog[]; count: number }>(
			'/blogs/' + blogId + '/comments',
			{
				filter: {
					...filter,
					order: ['id DESC'],
					limit: 3,
					where: {
						blogId: blogId || 0,
					},
				},
			}
		);

	const createCommentBlog = async (blogId: number, data: ICommentBlog) =>
		api.post(`/blogs/` + blogId + `/comments`, data);

	const getChildCommentById = async (commentId: number, filter?: Filter) =>
		api.get<{ data: IComment[]; count: number }>(
			'/comments/' + commentId + '/child-comments',
			{
				filter: {
					...filter,
					order: ['id DESC'],
					limit: 3,
				},
			}
		);

	const createChildComment = async (commentId: number, data: any) =>
		api.post('/comments/' + commentId + '/child-comments', data);

	const editCommentBlog = async (id: number, data: IComment) =>
		api.patch(`/comments/` + id, data);
	const deleteCommentBlog = async (id: number) =>
		api.delete('/comments/' + id);

	const editChildComment = async (id: number, data: IComment) =>
		api.patch(`/child-comments/` + id, data);
	const deleteChildComment = async (id: number) =>
		api.delete('/child-comments/' + id);

	const createContact = async (data: IContact) => api.post(`/contacts`, data);
	const createSubmission = async (data: ISubmission) =>
		api.post(`/submissions`, data);

	const getComments = async (filter?: Filter) => api.get(`/comments`, filter);
	const createComments = async (courseId: number, data: IComment) =>
		api.post(`/courses/` + courseId + `/comments`, data);

	const getEnrollments = async (filter?: Filter) =>
		api.get<IEnrollment>(`/enrollments`, {
			filter,
		});
	const createEnrollments = async (courseId: number) =>
		api.post(`/enrollments`, { courseId });

	const createNewsletters = async (data: INewsletters) =>
		api.post(`/newsletters`, data);

	const getReviews = async (filter?: Filter) =>
		api.get<{ data: any[]; count: number }>('/reviews', { filter });

	const getQuestionByProgramId = async (filter?: Filter) =>
		api.get<IQuestion[]>('/questions', { filter });

	const getChoiceByQuestionId = async (filter?: Filter) =>
		api.get<IChoice>('/choices', { filter });

	return {
		api,
		login,
		loginGoogle,
		register,
		getUserMe,
		initResetPassword,
		finishResetPassword,

		getNews,
		getNewDetailBySlug,
		getProjects,
		getProjectBySlug,

		//blog
		getBlogs,
		getBlogBySlug,
		getCommentBlogs,
		createCommentBlog,
		viewBlog,

		// contact
		createContact,

		// Product
		getProducts,
		getProductBySlug,

		// course
		getCourses,
		getCourseById,
		getCourseBySlug,
		getProgramsBySlug,
		getLessonsByProgramId,
		viewCourse,

		// programs
		getPrograms,

		// lessons
		getLessons,
		createLessonComplete,

		getReviews,
		createNewsletters,

		// enrollments
		getEnrollments,
		createEnrollments,

		// comments
		createComments,
		getComments,
		getChildCommentById,
		createChildComment,
		deleteChildComment,
		deleteCommentBlog,
		editCommentBlog,
		editChildComment,

		// Question
		getQuestionByProgramId,
		getChoiceByQuestionId,

		// Submission
		createSubmission,
	};
};

export const ServiceApi = createServiceApi();

export const setApiAuthorization = (token?: string | null) => {
	if (!token) {
		ServiceApi.api.deleteHeader('authorization');
		return;
	}
	ServiceApi.api.setHeaders({
		authorization: 'Bearer ' + token,
	});
};

export const isSuccess = <T = any>(res: ApiResponse<T> & any) => {
	return res.ok && (res.status === 200 || res.status === 204);
};
